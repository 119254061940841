<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3" v-if="$can('export_jamimas_transaction')">
      <b-col sm>
        <div>
          <b-button variant="success" @click="exportAll">Export All Transactions</b-button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <b-form-group
            class="mb-3"
            label="Search :"
            label-for="search"
            description="Searchable: Name, Email, JamimasId"
          >
            <b-input-group>
              <b-form-input
                id="search"
                type="search"
                v-model="filterText"
                placeholder="Name, Email, Jamimas ID"
                @keypress="doFilter"
                @keyup.delete="doFilter"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button
                 variant="secondary"
                 type="button"
                 @click="resetFilter"
                >
                  reset
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <b-form-group
           class="mb-3"
           label="Search By Transaction Status"
           label-for="label-status"
          >
            <b-form-select id="label-status" :options="statusOption" v-model="status" @change="doFilterStatus">
            </b-form-select>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm>
        <div>
          <b-form-group
           class="mb-3"
           label="Date :"
           label-for="dateRange"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <range-picker id="dateRange" :start="startDate" :end="endDate" @picker="doDateFilter"></range-picker>
              <b-input-group-append>
                <b-button type="button" variant="secondary" @click="resetDateFilter">Reset</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <vuetable
      ref="vuetable"
      @vuetable:load-error="handleLoadError"
      :api-url="apiUrl"
      :http-options="HttpOptions"
      :fields="fields"
      pagination-path=""
      :muti-sort="true"
      :sort-order="sortOrder"
      :append-params="moreParams"
      @vuetable:pagination-data="onPaginationData">
        <template slot="date-slot" slot-scope="prop">
          <span>{{ prop.rowData.created_at }}</span>
        </template>
        <template slot="id-slot" slot-scope="prop">
          <span>{{ prop.rowData.pawn_number }}</span>
        </template>
        <template slot="user-slot" slot-scope="prop">
          <span>{{ prop.rowData.users.name }} - {{ prop.rowData.users.email }}</span>
        </template>
        <template slot="gram-slot" slot-scope="prop">
          <span>{{ prop.rowData.gram }} Gram</span>
        </template>
        <template slot="cashout-slot" slot-scope="prop">
          <span>{{ prop.rowData.currency }}</span>
        </template>
        <template slot="tenor-slot" slot-scope="prop">
          <span>{{ prop.rowData.period_day }}</span>
        </template>
        <template slot="status-slot" slot-scope="prop">
          <span v-if="prop.rowData.status === 0">Diproses</span>
          <span v-else-if="prop.rowData.status === 1">Berjalan</span>
          <span v-else-if="prop.rowData.status === 2">Masa Tenggang</span>
          <span v-else-if="prop.rowData.status === 3">Gagal Bayar</span>
          <span v-else-if="prop.rowData.status === 4">Selesai</span>
          <span v-else-if="prop.rowData.status === 5">Ditolak</span>
        </template>
        <template slot="actions-slot" slot-scope ="prop">
          <div class="custom-actions" v-if="$can('detail_jamimas_transaction')">
            <button class="btn btn-success" @click="onAction('detail-item', prop.rowData)">Detail</button>
          </div>
        </template>
      </vuetable>
      <div class="vuetable-pagination ui basic segment grid">
        <vuetable-pagination-info ref="paginationInfo">
        </vuetable-pagination-info>
        <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
      </div>
  </div>
</template>

<script>
// import axios from 'axios'
import Vue from 'vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'

let startDate = '';
let endDate = '';
Vue.use(Vuetable);

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo
  },
  prop: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  data() {
    return {
      startDate: '',
      endDate: '',
      status: '',
      filterText: '',
      isLoading: false,
      statusOption: [
        { value: '', text: 'Choose Status' },
        { value: 0, text: 'Diproses' },
        { value: 1, text: 'Berjalan' },
        { value: 2, text: 'Masa Tenggang' },
        { value: 3, text: 'Gagal Bayar' },
        { value: 4, text: 'Selesai' },
        { value: 5, text: 'Ditolak' }
      ],
      errors: {
          code: '',
          message: '',
          status: ''
        },
      apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `pawn-transaction`,
      HttpOptions: {
        headers: {
          'Accept' : 'application/json',
          'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
        }
      },
      sortOrder: [
        {
          field: 'gold_pawn.created_at',
          sortField: 'gold_pawn.created_at',
          direction: 'desc'
        }
      ],
      moreParams: {},
      fields: [
        // {
        //   name: 'created_at',
        //   sortField: 'crypto_transactioon.created_at',
        //   title: 'Date'
        // },
        {
          name: 'date-slot',
          sortField: 'gold_pawn.created_at',
          title: 'Transaction Date'
        },
        {
          name: 'id-slot',
          sortField: 'gold_pawn.id',
          title: 'ID Jaminan Emas'
        },
        {
          name: 'user-slot',
          sortField: 'users.name',
          title: 'User'
        },
        {
          name: 'gram-slot',
          sortField: 'gold_pawn.gram',
          title: 'Gold Amount'
        },
        {
          name: 'cashout-slot',
          sortField: 'gold_pawn.currency',
          title: 'Cashout'
        },
        {
          name: 'tenor-slot',
          sortField: 'gold_pawn.period_day',
          title: 'Tenor (hari)'
        },
        {
          name: 'status-slot',
          sortField: 'gold_pawn.status',
          title: 'Status'
        },
        {
          name: 'actions-slot',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned'
        }
      ]
    }
  },
  methods : {
    onAction (action, data) {
      if (action == 'detail-item') {
        this.$router.push({ name: 'Jamimas Transaction Detail', params: { id: data.id, user_id: data.users.id } })
      }
    },
    doFilter () {
      this.$events.$emit('filter-set', this.filterText)
    },
    doFilterStatus () {
      this.$events.$emit('status-set', this.status)
    },
    resetFilter () {
      this.filterText = ''
      this.status = ''
      this.$events.$emit('filter-reset')
    },
    resetStatusFilter () {
      this.status = ''
      this.$events.$emit('status-reset')
    },
    handleLoadError(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem('access_token') != null) {
          localStorage.removeItem('access_token');
          this.$swal.fire(
            'Your session expired!',
            'Your session has expired. Please login again to access this page!',
            'error'
          ).then(() => {
            this.$router.push("/login")
          })
        }
      }else if(this.errors.code == 403) {
        this.$router.push("/403")
      }else if(this.errors.code == 500) {
        this.$router.push("/500")
      }
    },
    resetDateFilter () {
      this.startDate = ''
      this.endDate = ''
      this.status = ''
      this.$events.$emit('date-filter-reset')
    },
    allcap (value) {
      return value.toUpperCase()
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    onFilterSet (filterText) {
      this.moreParams = {
        'filter' : filterText
      }
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onStatusFilterSet () {
      if(this.status === '') {
        this.onFilterReset()
      }else{
      this.moreParams = {
        'status' : this.status
      }
      Vue.nextTick(() => this.$refs.vuetable.refresh())
      }
    },
    doDateFilter(value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
      this.$events.fire('date-set', [this.startDate, this.endDate])
    },
    onDateSet() {
      this.moreParams = {
        'start_from': this.startDate,
        'end_to': this.endDate
      }
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      this.moreParams = {}
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onDateFiltereset () {
      this.moreParams = {}
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    exportAll() {
      this.isLoading = true
      if (this.startDate != '' && this.endDate != '') {
        startDate = this.startDate;
        endDate = this.endDate;
      }else{
        endDate = this.$setDate.format('YYYY-MM-DD');
        startDate = this.$setDate.subtract(6, 'days').format('YYYY-MM-DD');
      }
      this.$http.get(`pawn-export/` + startDate + '/' + endDate)
      .then((result) =>{
        this.isLoading = false
        const exportPath = result.data.meta.data;
        window.location.href = exportPath
      }).catch((error) => {
        if (error.response) {
          this.isLoading = false
          this.errors.code = error.response.status;
          this.errors.status = error.response.data.meta.code;
          this.errors.headers = error.response.headers;
        }
      })
    }
  },
  mounted() {
    this.$events.$on('filter-set', eventData => this.onFilterSet(eventData))
    this.$events.$on('date-set', eventData => this.onDateSet(eventData))
    this.$events.$on('status-set', eventData => this.onStatusFilterSet(eventData))
    this.$events.$on('filter-reset', () => this.onFilterReset())
    this.$events.$on('date-filter-reset', () => this.onDateFiltereset())
  }
}

</script>
<style>
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}
</style>
<style src='spinkit/scss/spinkit.scss' lang='scss'></style>
